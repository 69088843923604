import React, { SVGProps } from 'react';

const SvgKeywee = (props: SVGProps<SVGSVGElement>) => (
  <svg width={85} height={24} viewBox="0 0 85 24" {...props}>
    <path
      d="M61.8 10.383c.308-1.748 1.49-2.922 3.159-2.922 1.387 0 2.491 1.095 2.543 2.922H61.8zm3.236-5.479c-3.93 0-6.652 2.87-6.652 6.966 0 3.782 2.697 6.965 7.037 6.965 3.288 0 5.291-1.852 5.291-1.852l-1.258-2.348s-1.67 1.408-3.801 1.408c-1.978 0-3.725-1.252-3.93-3.704h9.066s.077-.73.077-1.122c0-3.521-2.054-6.313-5.83-6.313zM42.691 7.695V5.217h-6.19v2.479h1.516l-2.363 6.73a7.001 7.001 0 00-.36 1.383h-.077s-.051-.73-.282-1.383l-2.39-6.73h1.542V5.217H27.64v2.479h1.567l4.495 11.217-.36.835c-.41.965-1.258 1.539-2.312 1.539-1.207 0-1.977-.626-1.977-.626L28 23.087s1.284.913 3.185.913c2.234 0 4.186-1.122 5.188-3.757l3.047-7.917.002.01 1.78-4.64h1.49zm9.199 0h1.592l-1.746 6.496c-.154.652-.206 1.435-.206 1.435h-.077s-.05-.783-.205-1.435l-2.44-8.948H45.93l-2.466 8.948a10.255 10.255 0 00-.205 1.435h-.077s-.051-.783-.205-1.435l-.903-3.407-1.776 4.685.855 3.053h3.699l2.234-7.8c.18-.679.231-1.461.231-1.461h.077s.052.782.231 1.46l2.26 7.8h3.7l3.03-10.825h1.567V5.217H51.89v2.479zm-39.664 7.879l-1.85-3.287c-.513-.887-.975-1.122-1.001-1.122v-.052l2.85-3.417h1.696V5.217H7.628v2.479h1.516l-2.132 2.556H5.29V0H0v2.478h2.029v13.565h-1.9v2.479h6.575v-2.479H5.29v-3.365h.77c.463 0 .874.026 1.13.496l2.364 4.2c.513.965.95 1.148 2.054 1.148h2.44v-2.479h-.745c-.616 0-.847-.078-1.078-.47zm6.033-5.191c.309-1.748 1.49-2.922 3.16-2.922 1.387 0 2.49 1.095 2.542 2.922H18.26zm3.237-5.479c-3.93 0-6.653 2.87-6.653 6.966 0 3.782 2.697 6.965 7.038 6.965 3.287 0 5.29-1.852 5.29-1.852l-1.258-2.348s-1.67 1.408-3.801 1.408c-1.978 0-3.724-1.252-3.93-3.704h9.067s.077-.73.077-1.122c0-3.521-2.055-6.313-5.83-6.313zm54.14 5.479c.309-1.748 1.49-2.922 3.16-2.922 1.387 0 2.491 1.095 2.543 2.922h-5.702zm9.067.834c0-3.521-2.055-6.313-5.83-6.313-3.93 0-6.653 2.87-6.653 6.966 0 3.782 2.697 6.965 7.038 6.965 3.288 0 5.291-1.852 5.291-1.852l-1.259-2.348s-1.669 1.408-3.8 1.408c-1.979 0-3.725-1.252-3.93-3.704h9.066s.077-.73.077-1.122z"
      fill="#314A75"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgKeywee;
