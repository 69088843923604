import userEventLogger from '@queries/user/userEventLogger';
import mixpanel from 'mixpanel-browser';
import { events } from './events';

export const identifyUser = (email: string) => {
  userEventLogger.setUser(email);
  if (process.env.NODE_ENV === 'production') {
    mixpanel.identify(email);
    mixpanel.track('user_login');
  }
};

export const trackEvent = (event_name: keyof typeof events, params?: Record<string, unknown>) => {
  userEventLogger.log({
    eventName: event_name,
    params: params
  });

  if (process.env.NODE_ENV === 'production') {
    mixpanel.track(event_name, params);
  }
};

export const reset = () => {
  if (process.env.NODE_ENV === 'production') {
    mixpanel.reset();
  }
};
