import axios from 'axios';
import client from '../../apollo';
import { reset } from '@services/MixPanel';
import IsAuthenticated from './IsAuthenticated';
import { KSS_ACCESS_TOKEN } from '@stores/user';
import { ModificationChecker } from '@services/ModificationChecker';
import { API_URL } from '../../config';

const Authentication = {
  signOut(source?: string) {
    void client.clearStore();
    localStorage.removeItem(KSS_ACCESS_TOKEN);
    ModificationChecker.clearStorage();

    // reset mixpanel
    reset();

    // refreshing the page

    if (source === 'apolloNetworkError') {
      const queryParams = new URLSearchParams(window.location.search || '');

      const email = queryParams.get('email');
      const utm_medium = queryParams.get('utm_medium');
      const utm_source = queryParams.get('utm_source');

      if (utm_medium && email && utm_source) {
        window.history.pushState({ email, utm_medium, utm_source }, '', '/login');
      }
    }

    window.location.replace('/login');
  },

  getToken() {
    return localStorage.getItem(KSS_ACCESS_TOKEN);
  },

  async isAuthenticated() {
    const token = this.getToken();
    if (token) {
      return axios({
        url: `${API_URL}/authentication/verify`,
        method: 'POST',
        headers: {
          'X-Access-Token': token
        }
      });
    }

    return !!token;
  }
};
export { IsAuthenticated };
export default Authentication;
