import React from 'react';
import { observable, action } from 'mobx';
import { CheckExternalUser } from '@queries/__generated__/CheckExternalUser';
import { v4 as uuidv4 } from 'uuid';

type Required<T> = {
  value: T;
  error: string;
};

interface Publisher {
  id: string;
  publisherId: Required<string | null>;
  useCases: Required<string[]>;
  manageApiAccess: boolean;
  receiveCreativeReviewEmails: boolean;
  contentEnhancerAdmin: boolean;
}

const emailRegexp = new RegExp(
  /* eslint-disable-next-line */
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const emptyValue = { error: ' ', value: '' };

class EditUser {
  @observable email: Required<string> = {
    value: '',
    error: ' '
  };
  @observable firstName: Required<string> = {
    value: '',
    error: ' '
  };
  @observable lastName: Required<string> = {
    value: '',
    error: ' '
  };
  @observable publishers: Publisher[] = [];
  @observable publishersError = '';
  @observable deprecated = false;

  getEmptyPublisher = () => ({
    id: uuidv4(),
    publisherId: {
      value: null,
      error: ' '
    },
    useCases: {
      value: [],
      error: ' '
    },
    manageApiAccess: false,
    receiveCreativeReviewEmails: false,
    contentEnhancerAdmin: false
  });
  @action changeEmail = (value: string) => {
    this.email = { error: ' ', value };
  };

  @action changeDeprecated = (value: boolean) => {
    this.deprecated = value;
  };

  @action handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'firstName':
      case 'lastName':
      case 'email':
        this[event.target.name as 'firstName' | 'lastName' | 'email'] = {
          error: ' ',
          value: event.target.value
        };
        break;

      default:
        throw new Error('This field is not supported');
    }
  };

  @action addPublisher = () => {
    this.publishers.push(this.getEmptyPublisher());
    this.publishersError = '';
  };

  @action removePublisher = (index: number) => {
    let newPublishers = [];
    switch (index) {
      case 0:
        newPublishers = this.publishers.slice(1);
        break;
      case this.publishers.length - 1:
        newPublishers = this.publishers.slice(0, index);
        break;
      default:
        newPublishers = [...this.publishers.slice(0, index), ...this.publishers.slice(index + 1)];
    }
    this.publishers = newPublishers;
  };

  @action changePublisherId = (index: number) => {
    return (publisherId: string) => {
      this.publishers[index].publisherId = { error: ' ', value: publisherId };
    };
  };

  @action changeUseCase = (index: number) => {
    return (event: React.ChangeEvent<{ value: unknown }>) => {
      this.publishers[index].useCases = {
        error: ' ',
        value: event.target.value as string[]
      };
    };
  };

  @action changeApiAccessPublisherPermissions = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.publishers[index][event.target.name as 'manageApiAccess'] = event.target.checked;
    };
  };

  @action changeReceiveCreativeReviewEmails = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.publishers[index][event.target.name as 'receiveCreativeReviewEmails'] = event.target.checked;
    };
  };

  @action changeContentEnhancerAdminPermission = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.publishers[index][event.target.name as 'contentEnhancerAdmin'] = event.target.checked;
    };
  };

  @action populateDataOnCheck = (data: CheckExternalUser | undefined) => {
    this.firstName = { error: ' ', value: data?.user?.firstName ?? '' };
    this.lastName = { error: ' ', value: data?.user?.lastName ?? '' };
    this.deprecated = Boolean(data?.user?.deprecated);
    this.publishers =
      data?.user?.permissions.map(permission => {
        return {
          id: uuidv4(),
          publisherId: { error: ' ', value: permission.publisherId },
          useCases: {
            error: ' ',
            value: permission.useCases.filter(useCase => useCase.enabled).map(useCase => useCase.name)
          },
          manageApiAccess: permission.manageApiAccess,
          receiveCreativeReviewEmails: permission.receiveCreativeReviewEmails,
          contentEnhancerAdmin: permission.contentEnhancerAdmin
        };
      }) ?? [];
  };

  normalizePublishers = () => {
    return this.publishers.map(publisher => {
      return {
        publisherId: publisher.publisherId.value as string,
        useCases: publisher.useCases.value as string[],
        manageApiAccess: publisher.manageApiAccess,
        receiveCreativeReviewEmails: publisher.receiveCreativeReviewEmails,
        contentEnhancerAdmin: publisher.contentEnhancerAdmin
      };
    });
  };

  prepareForCreateUser = () => {
    return {
      email: this.email.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      publishers: this.normalizePublishers(),
      deprecate: this.deprecated
    };
  };

  @action validateEmail = () => {
    const { value } = this.email;
    if (!value.length) {
      this.email.error = 'Please enter email address';
      return false;
    } else {
      if (emailRegexp.test(value.trim())) {
        this.email.error = ' ';
        return true;
      } else {
        this.email.error = 'Please enter a valid email address';
        return false;
      }
    }
  };

  @action validateFields = () => {
    let errors = 0;
    if (!this.firstName.value) {
      this.firstName.error = 'First name is required';
      errors += 1;
    }
    if (!this.lastName.value) {
      this.lastName.error = 'Last name is required';
      errors += 1;
    }
    if (!this.publishers.length) {
      this.publishersError = 'At least one publisher is required';
      errors += 1;
    }
    this.publishers.forEach(publisher => {
      if (!publisher.publisherId.value) {
        publisher.publisherId.error = 'Publisher is required';
        errors += 1;
      }
      if (!publisher.useCases.value.length) {
        publisher.useCases.error = 'Use case is required';
        errors += 1;
      }
    });
    return errors;
  };

  @action resetForm = () => {
    this.email = emptyValue;
    this.firstName = emptyValue;
    this.lastName = emptyValue;
    this.publishers = [];
    this.publishersError = '';
  };
}

export const EditUserContext = React.createContext(new EditUser());

export const useEditUserStore = () => React.useContext(EditUserContext);

export default EditUser;
