import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

interface Props {
  open: boolean;
  handleClose: () => void;
  onLogin: () => void;
}

const LogoutWarning = ({ open, handleClose, onLogin }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Time to upgrade</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Typography>It seems you're running an old version of the dashboard. Login again to continue.</Typography>
          <Typography>Thanks for your patience!</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onLogin} variant="contained" color="primary" autoFocus>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutWarning;
