import { UseCase } from '@queries/globalTypes';
import { observable, action } from 'mobx';
import { PermissionsQuery_me_permissions_useCases } from '@queries/__generated__/PermissionsQuery';
import { matchUseCaseToLabel } from '@utils/transformUseCase';
import DropdownItem from '@utils/DropdownItem';

class NewPosts {
  @observable useCase: UseCase | null;
  @observable useCases: DropdownItem<UseCase>[];
  @observable rawUseCases: PermissionsQuery_me_permissions_useCases[];
  constructor() {
    this.useCase = null;
    this.useCases = [];
    this.rawUseCases = [];
  }
  @action initializeUseCases(useCase: PermissionsQuery_me_permissions_useCases[]) {
    const useCases = useCase
      .filter(useCase => useCase.enabled && useCase.name !== 'GoogleDiscovery')
      .map(useCase => new DropdownItem(useCase.name, matchUseCaseToLabel(useCase.name as UseCase)));
    this.useCases = useCases;
    this.rawUseCases = useCase;

    if (!this.useCases.length) return;

    this.changeUseCase(this.useCases[0].value);
  }
  @action changeUseCase(useCase: UseCase) {
    this.useCase = useCase;
  }
}
export default NewPosts;
