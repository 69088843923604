import { observable, action } from 'mobx';

class Tab<T extends string> {
  @observable activeTab: T;
  @action changeTab = (nextTab: string) => {
    this.activeTab = nextTab as T;
  };
  constructor(initialTab: T) {
    this.activeTab = initialTab;
  }
}

export default Tab;
