import startOfDay from 'date-fns/start_of_day';
import startOfMonth from 'date-fns/start_of_month';
import endOfDay from 'date-fns/end_of_day';
import addDays from 'date-fns/add_days';
import addWeeks from 'date-fns/add_weeks';
import addMonths from 'date-fns/add_months';
import endOfMonth from 'date-fns/end_of_month';
import addYears from 'date-fns/add_years';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/difference_in_days';

interface CustomDatepickerValue {
  startDate: string;
  endDate: string;
}

export enum DatePresetsEnum {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST7DAYS = 'Last 7 days',
  THISMONTH = 'This month',
  LAST30DAYS = 'Last 30 days',
  LASTMONTH = 'Last month',
  LAST3MONTH = 'Last 3 months',
  LASTTWOMONTHS = 'Last 2 months',

  PASTWEEK = 'Past week',
  PASTMONTH = 'Past month',
  PAST6MONTHS = 'Past 6 months',
  PASTYEAR = 'Past year'
}

export const DatePresets = {
  [DatePresetsEnum.TODAY]: (today: Date | string) => ({
    startDate: format(startOfDay(today)),
    endDate: format(endOfDay(today))
  }),
  [DatePresetsEnum.YESTERDAY]: (today: Date | string) => ({
    startDate: format(startOfDay(addDays(today, -1))),
    endDate: format(endOfDay(addDays(today, -1)))
  }),
  [DatePresetsEnum.LAST7DAYS]: (today: Date | string) => ({
    startDate: format(startOfDay(addDays(today, -7))),
    endDate: format(endOfDay(addDays(today, -1)))
  }),
  [DatePresetsEnum.LAST30DAYS]: (today: Date | string) => ({
    startDate: format(startOfDay(addDays(today, -30))),
    endDate: format(endOfDay(addDays(today, -1)))
  }),
  [DatePresetsEnum.THISMONTH]: (today: Date | string) => ({
    startDate: format(startOfMonth(today)),
    endDate: format(endOfDay(today))
  }),
  [DatePresetsEnum.LASTMONTH]: (today: Date | string) => ({
    startDate: format(startOfMonth(addMonths(today, -1))),
    endDate: format(endOfMonth(addMonths(today, -1)))
  }),
  [DatePresetsEnum.LASTTWOMONTHS]: (today: Date | string) => ({
    startDate: format(startOfMonth(addMonths(today, -2))),
    endDate: format(endOfMonth(addMonths(today, -1)))
  }),
  [DatePresetsEnum.LAST3MONTH]: (today: Date | string) => ({
    startDate: format(startOfMonth(addMonths(today, -3))),
    endDate: format(endOfMonth(addMonths(today, -1)))
  }),
  [DatePresetsEnum.PASTWEEK]: (today: Date | string) => ({
    startDate: format(startOfDay(addWeeks(today, -1))),
    endDate: format(endOfDay(today))
  }),
  [DatePresetsEnum.PASTMONTH]: (today: Date | string) => ({
    startDate: format(startOfDay(addMonths(today, -1))),
    endDate: format(endOfDay(today))
  }),
  [DatePresetsEnum.PAST6MONTHS]: (today: Date | string) => ({
    startDate: format(startOfDay(addMonths(today, -6))),
    endDate: format(endOfDay(today))
  }),
  [DatePresetsEnum.PASTYEAR]: (today: Date | string) => ({
    startDate: format(startOfDay(addYears(today, -1))),
    endDate: format(endOfDay(today))
  })
};

export function getDatepickerForPreset(preset: DatePresetsEnum) {
  let mappedPreset: ((today: string | Date) => { startDate: string; endDate: string }) | null = null;
  if (!DatePresets[preset]) {
    mappedPreset = DatePresets['Last month'];
  } else {
    mappedPreset = DatePresets[preset];
  }
  return {
    label: preset,
    dateRange: mappedPreset(new Date())
  };
}

export function getDatepickerForCustomDates(dates: CustomDatepickerValue) {
  return {
    label: `${format(dates.startDate, 'MM-DD-YYYY')} - ${format(dates.endDate, 'MM-DD-YYYY')}`,
    dateRange: dates
  };
}
export function getPreviousDatepickerForCustomDates(dates: CustomDatepickerValue) {
  const endDate = addDays(dates.endDate, -1);
  return {
    startDate: format(addDays(endDate, -differenceInDays(dates.startDate, dates.endDate))),
    endDate: format(endDate)
  };
}

export function getPreviousDatesForPreset(preset: DatePresetsEnum) {
  const today = new Date();
  if (preset === DatePresetsEnum.THISMONTH) {
    return DatePresets[DatePresetsEnum.LASTMONTH](today);
  }
  if (preset === DatePresetsEnum.TODAY) {
    return DatePresets[DatePresetsEnum.YESTERDAY](today);
  }
  if (preset === DatePresetsEnum.LAST30DAYS) {
    const currentDateRange = DatePresets[preset](addDays(today, -1));
    return DatePresets[preset](currentDateRange.startDate);
  }
  const currentDateRange = DatePresets[preset](today);
  return DatePresets[preset](currentDateRange.startDate);
}

export function getToday() {
  return format(startOfDay(new Date()));
}
