import { API_URL } from './config';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import Authentication from '@services/Authentication';
import { createUploadLink } from 'apollo-upload-client';
import introspectionQueryResultData from '@queries/fragments/fragmentTypes';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({
  fragmentMatcher,
  addTypename: false
});

const httpLink = createUploadLink({
  uri: `${API_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Access-Token': Authentication.getToken() || null
    }
  };
});
const logoutLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    Authentication.signOut('apolloNetworkError');
  }
});

const client = new ApolloClient({
  // @ts-ignore
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache
});

export default client;
