import axios from 'axios';
import { isLocalHost } from '@utils/location';
import { appErrorHandler } from '@utils/appErrorHandler';

export class ModificationChecker {
  static last_modified_key = 'app_last_modified';

  static headRequestIndex = async (headers = {}) =>
    axios({
      url: '/index.html',
      method: 'HEAD',
      headers: headers
    });

  static getLastModification = async (cb: () => void) => {
    if (isLocalHost()) {
      return;
    }
    try {
      const headRequest = await ModificationChecker.headRequestIndex();
      if ('last-modified' in headRequest.headers) {
        const storedLastModified = ModificationChecker.getStorage();
        if (!storedLastModified || storedLastModified !== headRequest.headers['last-modified']) {
          ModificationChecker.setStorage(headRequest.headers['last-modified']);
        }
        await ModificationChecker.checkAppRelevance(cb);
      }
    } catch (error) {
      appErrorHandler(error);
      console.error('Head request failed', error);
    }
  };

  static checkAppRelevance = async (onUpdate?: () => void) => {
    if (isLocalHost()) {
      return;
    }
    const storedLastModified = ModificationChecker.getStorage();
    if (storedLastModified) {
      try {
        await ModificationChecker.headRequestIndex({
          'If-Modified-Since': storedLastModified
        });
        if (onUpdate) {
          onUpdate();
        }
      } catch (error) {
        if (error.response && error.response.status !== 304) {
          appErrorHandler(error);
          console.error(error);
        }
      }
    }
  };

  static getStorage = () => {
    return localStorage.getItem(ModificationChecker.last_modified_key);
  };

  static setStorage = (value: string) => {
    localStorage.setItem(ModificationChecker.last_modified_key, value);
  };

  static clearStorage = () => {
    localStorage.removeItem(ModificationChecker.last_modified_key);
  };
}
