import { observable, computed, action } from 'mobx';
import { createContext, useContext } from 'react';
import {
  DatePresetsEnum,
  getDatepickerForCustomDates,
  getPreviousDatepickerForCustomDates,
  getDatepickerForPreset,
  getPreviousDatesForPreset
} from '@constants/DatePresets';

type CustomDate = { startDate: string; endDate: string };

class Datepicker {
  private _keyToSaveTo = '';
  private _domainKey = 'datepicker';
  private _initialized = false;
  @observable datepicker: DatePresetsEnum | CustomDate;
  constructor(initialiDate: DatePresetsEnum) {
    this.datepicker = initialiDate;
  }
  @action initialize(
    fromKey: string,
    defaultDate: DatePresetsEnum,
    initialDate: DatePresetsEnum | CustomDate | null = null
  ) {
    if (this._initialized) {
      return;
    }
    this._initialized = true;
    this._keyToSaveTo = `${fromKey}.${this._domainKey}`;
    if (initialDate) {
      this.changeDatepicker(initialDate);
      return;
    }
    const persistedDatepicker = localStorage.getItem(this._keyToSaveTo);
    if (persistedDatepicker) {
      let datepickerValue;
      try {
        datepickerValue = JSON.parse(persistedDatepicker);
      } catch (err) {
        datepickerValue = persistedDatepicker.replace(/['"]/g, '');
      }
      this.changeDatepicker(datepickerValue);
    } else {
      this.changeDatepicker(defaultDate);
    }
  }
  @action changeDatepicker(date: DatePresetsEnum | CustomDate) {
    if (!this._initialized) {
      throw new Error('Datepicker has not been initialized');
    }
    this.datepicker = date;
    localStorage.setItem(`${this._keyToSaveTo}`, typeof date === 'string' ? date : JSON.stringify(date));
  }
  @computed get value() {
    if (typeof this.datepicker === 'object') {
      return {
        ...getDatepickerForCustomDates(this.datepicker),
        previousDateRange: getPreviousDatepickerForCustomDates(this.datepicker)
      };
    }
    return {
      ...getDatepickerForPreset(this.datepicker),
      previousDateRange: getPreviousDatesForPreset(this.datepicker)
    };
  }
}

export const DatepickerContext = createContext<Datepicker | undefined>(undefined);

export const useDatepicker = () => {
  const datepicker = useContext(DatepickerContext);
  if (datepicker === undefined) {
    throw new Error('Datepicker is used outside of its Provider');
  }
  return datepicker;
};

export default Datepicker;
