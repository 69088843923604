import { observable, action } from 'mobx';
import DropdownItem from '@utils/DropdownItem';
import { getKeysList } from '@utils/getKeysList';
import { trackEvent } from '@services/MixPanel';

function createMetrics(fields: string[]) {
  return fields.map(field => new DropdownItem(field));
}

const initialMetrics = [new DropdownItem('spend'), new DropdownItem('clicks')];

class PerformanceGraph {
  @observable allMetrics = initialMetrics;
  @observable metrics = initialMetrics;
  @action initializeMetrics = (
    data: { [k: string]: any }[],
    filterMetrics: string[],
    initialMetric1?: DropdownItem<string>,
    initialMetric2?: DropdownItem<string>
  ) => {
    if (data.length) {
      this.allMetrics = [
        new DropdownItem('', 'Choose value'),
        ...createMetrics(getKeysList(data[0], ['dateInterval']))
      ].filter(metric => !filterMetrics.includes(metric.value));

      const [metric1, metric2] = this.metrics;

      this.metrics[0] = initialMetric1 || metric1;
      this.metrics[1] = initialMetric2 || metric2;
    }
  };
  @action changeMetric = (metricIndex: number) => (metric: DropdownItem) => {
    trackEvent('performance_graph_metric_change', {
      metricIndex,
      metric: metric.value
    });
    this.metrics[metricIndex] = metric;
  };
}

export default PerformanceGraph;
