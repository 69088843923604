import User from './user';
import { reaction } from 'mobx';
import UseCase from './UseCase';
import EditUser from './editUser';
import Permissions from './permissions';
import NewPosts from './pages/NewPosts';
import UrlParamStore from './urlParamStore';
import { createContext, useContext } from 'react';
import ResetPassword from '@stores/resetPassword';
import { omittedColumnsByUseCase } from '@constants/useCaseConfig';

class RootStore {
  userStore: User;
  editUser: EditUser;
  newPosts: NewPosts;
  permissions: Permissions;
  urlParamStore: UrlParamStore;
  resetPassword: ResetPassword;

  audDevStore: UseCase;
  subStore: UseCase;
  brcStore: UseCase;
  adsStore: UseCase;
  ecoStore: UseCase;
  dscStore: UseCase;

  constructor() {
    this.editUser = new EditUser();
    this.newPosts = new NewPosts();
    this.userStore = new User(this);
    this.permissions = new Permissions();
    this.resetPassword = new ResetPassword();
    this.urlParamStore = new UrlParamStore();

    this.audDevStore = new UseCase(this, {
      name: 'aud_dev',
      withAttributionWindow: true,
      pages: { overview: true, reports: true },
      omittedColumns: omittedColumnsByUseCase['aud_dev']
    });

    this.subStore = new UseCase(this, {
      name: 'sub',
      pages: { reports: true },
      withAttributionWindow: true,
      omittedColumns: omittedColumnsByUseCase['sub']
    });

    this.brcStore = new UseCase(this, {
      name: 'brc',
      pages: { reports: true },
      omittedColumns: omittedColumnsByUseCase['brc']
    });

    this.adsStore = new UseCase(this, {
      name: 'ads',
      pages: { reports: true },
      omittedColumns: omittedColumnsByUseCase['ads']
    });

    this.ecoStore = new UseCase(this, {
      name: 'eco',
      pages: { reports: true },
      withAttributionWindow: true,
      omittedColumns: omittedColumnsByUseCase['eco']
    });

    this.dscStore = new UseCase(this, {
      name: 'dsc',
      omittedColumns: [],
      pages: { overview: true, reports: true }
    });

    reaction(
      () => this.userStore.user,
      user => {
        if (user) {
          const base = `${user.id}_${user.userType}`;
          this.audDevStore.initialize(base);
          this.subStore.initialize(base);
          this.brcStore.initialize(base);
          this.adsStore.initialize(base);
          this.ecoStore.initialize(base);
          this.dscStore.initialize(base);
          this.userStore.initializePublisher(base, user.publishers[0].id, this.urlParamStore.publisher);
        }
      }
    );
  }
}

export const RootStoreContext = createContext<RootStore | undefined>(undefined);

export const useStore = () => {
  const store = useContext(RootStoreContext);
  if (store === undefined) {
    throw new Error('Store is used outside of its provider');
  }
  return store;
};
export const store = new RootStore();

export default RootStore;
