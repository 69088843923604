import { AttributionWindow } from '@queries/globalTypes';

const attributionWindowLabels: Record<AttributionWindow, string> = {
  [AttributionWindow.click_28d_view_1d]: '28d click & 1d view',
  [AttributionWindow.click_1d]: '1d click',
  [AttributionWindow.click_28d]: '28d click',
  [AttributionWindow.click_7d]: '7d click',
  [AttributionWindow.view_1d]: '1d view',
  [AttributionWindow.view_28d]: '28d view',
  [AttributionWindow.view_7d]: '7d view',
  [AttributionWindow.default]: '7d click & 1d view'
};

export { attributionWindowLabels };
