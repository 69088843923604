import Tab from '../tab';
import Datepicker from '../datepicker';
import CampaignTypeFilter from '../campaignTypeFilter';
import ColumnFilter from '../filterColumns';
import TableSelection from '../tableSelection';
import Search from '../search';
import Pagination from '../pagination';
import Creative from '../creative';
import PerformanceGraph from '../performanceGraph';
import AgeGenderBreakdown from '../AgeGenderBreakdown';
import AttributionWindow from '../attributionWindow';
import { DatePresetsEnum } from '@constants/DatePresets';
import Export from '../export';
import UseCase from '../UseCase';
import { observable, action, reaction } from 'mobx';
import { trackEvent } from '@services/MixPanel';
import { createContext, useContext } from 'react';
import SelectionCounter from '@stores/selectionCounter';

class Reports {
  _rootStore: UseCase;
  datepicker: Datepicker;
  activePage: Tab<'campaign' | 'story'>;
  campaignTypeFilter: CampaignTypeFilter;
  campaignSelection: TableSelection;
  storySelection: TableSelection;
  columnFilter: ColumnFilter;
  tableSearch: Search;
  campaignPagination: Pagination;
  storiesPagination: Pagination;
  creative: Creative;
  performanceGraph: PerformanceGraph;
  AgeGenderBreakdown: AgeGenderBreakdown;
  storyPerformanceGraph: PerformanceGraph;
  storyByCreativeTypePerformanceGraph: PerformanceGraph;
  storyByCreativeTypePieGraph: PerformanceGraph;
  attributionWindow: AttributionWindow;
  reportsExport: Export;
  selectionCounter: SelectionCounter;

  // this is used in exports store
  @observable conversionIds: string[] = [];
  @action updateConversionIds = (conversionIds: string[]) => {
    this.conversionIds = conversionIds;
  };

  constructor(rootStore: UseCase) {
    this._rootStore = rootStore;
    this.datepicker = new Datepicker(DatePresetsEnum.LAST7DAYS);
    this.activePage = new Tab<'campaign' | 'story'>('campaign');
    this.campaignTypeFilter = new CampaignTypeFilter();
    this.campaignSelection = new TableSelection();
    this.storySelection = new TableSelection();
    this.columnFilter = new ColumnFilter();
    this.tableSearch = new Search();
    this.campaignPagination = new Pagination();
    this.storiesPagination = new Pagination();
    this.creative = new Creative(this);
    this.performanceGraph = new PerformanceGraph();
    this.AgeGenderBreakdown = new AgeGenderBreakdown();
    this.storyPerformanceGraph = new PerformanceGraph();
    this.storyByCreativeTypePerformanceGraph = new PerformanceGraph();
    this.storyByCreativeTypePieGraph = new PerformanceGraph();
    this.attributionWindow = new AttributionWindow();
    this.reportsExport = new Export(this);
    this.selectionCounter = new SelectionCounter();

    reaction(
      () => this.tableSearch.value,
      value => {
        trackEvent('table_search', {
          table: this.activePage.activeTab,
          value: value
        });
      },
      {
        delay: 500
      }
    );
    reaction(
      () => this.columnFilter.value,
      columns => {
        trackEvent('table_column_filter_change', {
          table: this.activePage.activeTab,
          value: columns
        });
      }
    );
  }
}

export const ReportsContext = createContext<Reports | undefined>(undefined);

export const useReportsStore = () => {
  const ctx = useContext(ReportsContext);
  if (ctx === undefined) {
    throw new Error('Reports context was not initialized');
  }
  return ctx;
};

export default Reports;
