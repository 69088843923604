import { getName } from '@services/Name';

class DropdownItem<L = string> {
  value: any;
  label: any;
  constructor(value: string, label?: L) {
    this.value = value;
    this.label = label || value;
    if (label && typeof label === 'string') {
      this.label = getName(label);
    } else if (!label) {
      this.label = getName(value);
    }
  }
}

export default DropdownItem;
