export const currencyFields = [
  'cpc',
  'lifetimeBudget',
  'lifetimeSpend',
  'cppv',
  'pixelCostPerPageView',
  'remainingBudget',
  'pixelCostPerPageView',
  'revenue',
  'profit',
  'costPerVideoView',
  'videoP25CostPerWatchedAction',
  'videoP50CostPerWatchedAction',
  'videoP75CostPerWatchedAction',
  'videoP100CostPerWatchedAction',
  'videoThruplayCostPerWatchedAction',

  //   Google Insights Fields
  'cost',
  'cpm'
];

export const percentFields = ['ctr', 'newReturningRatio', 'revenueRoi', 'revenueRoiGoal'];

export const fieldToLabelMap: Record<string, string> = {
  clicks: 'Clicks',
  impressions: 'Impressions',
  spend: 'Media Spend',
  ctr: 'CTR',
  cpc: 'CPC',

  pixelPageViews: 'Page Views',
  pixelCostPerPageView: 'CPPV',
  pixelAveragePageViewsPerSession: 'PVPS',
  postReactions: 'Reactions',
  postComments: 'Comments',
  postShares: 'Shares',
  revenue: 'Revenue',
  profit: 'Profit',
  revenueRoi: 'ROI',
  revenueRoiGoal: 'ROI Goal',
  videoThruplayWatchedActions: 'Thruplay',
  videoThruplayCostPerWatchedAction: 'Cost per Thruplay',
  videoView: 'Video views',
  costPerVideoView: 'Cost per video view',
  videoP25WatchedActions: 'Video 25% watched',
  videoP25CostPerWatchedAction: 'Cost per Video 25% Watched',
  videoP50WatchedActions: 'Video 50% watched',
  videoP50CostPerWatchedAction: 'Cost per Video 50% Watched',
  videoP75WatchedActions: 'Video 75% watched',
  videoP75CostPerWatchedAction: 'Cost per Video 75% Watched',
  videoP100WatchedActions: 'Video 100% watched',
  videoP100CostPerWatchedAction: 'Cost per Video 100% Watched',

  // Google Insights Fields
  cost: 'Cost',
  cpm: 'CPM'
};
