import 'regenerator-runtime/runtime';
import 'core-js';
import 'whatwg-fetch';
import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserSupportErrorBoundary } from '@services/Error/BrowserSupport';
import { CatchAllErrorBoundary } from '@services/Error/CatchAll';
import { Loader } from '@components/Loader';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import mixpanel from 'mixpanel-browser';
import { appErrorHandler, appLogoutHandler } from '@utils/appErrorHandler';
import '@utils/kw/window.kw';
import './index.css';

mixpanel.init('d22fd6320591907d49e865ce95840254');

const AppSetup = hot(React.lazy(() => import('./servicesSetup' /* webpackChunkName: "servicesSetup" */)));

const App = (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserSupportErrorBoundary>
      <div className="adBanner" id="ad_banner_id" />
      <CatchAllErrorBoundary onException={appErrorHandler} onLogout={appLogoutHandler}>
        <Suspense fallback={<Loader />}>
          <AppSetup />
        </Suspense>
      </CatchAllErrorBoundary>
    </BrowserSupportErrorBoundary>
  </MuiThemeProvider>
);

// addBanner div is added to check the adBlocker
// acting as bait for adblocker and we're expecting adblocker to remove that's how we know it is on
// tested in chrome with uBlock and AdBlock
// tested in safari with AdBlock
// tested in mozilla with turned off enhanced tracking protection and AdBlock
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(App, document.getElementById('root'));
