import { observable, action } from 'mobx';

class Pagination {
  private _rowsPerPageKeyToSaveTo = '';
  private _rowsPerPageDomainKey = 'rowsPerPage';
  private _rowsPerPageInitialized = false;
  @observable page = 0;
  @observable rowsPerPage = 5;
  @action changePage = (page: number) => {
    this.page = page;
  };
  @action initializeRowsPerPage = (fromKey: string, defaultValue: number) => {
    if (this._rowsPerPageInitialized) {
      return;
    }
    this._rowsPerPageInitialized = true;
    this._rowsPerPageKeyToSaveTo = `${fromKey}.${this._rowsPerPageDomainKey}`;
    const persistedRowsPerPage = localStorage.getItem(this._rowsPerPageKeyToSaveTo);
    if (persistedRowsPerPage) {
      this.changeRowsPerPage(parseInt(persistedRowsPerPage));
    } else {
      this.changeRowsPerPage(defaultValue);
    }
  };
  @action changeRowsPerPage = (rows: number) => {
    this.rowsPerPage = rows;
    this.page = 0;
    localStorage.setItem(this._rowsPerPageKeyToSaveTo, JSON.stringify(rows));
  };
  @action reset = () => {
    this.page = 0;
    const persistedRowsPerPage = localStorage.getItem(this._rowsPerPageKeyToSaveTo);
    if (persistedRowsPerPage) {
      this.changeRowsPerPage(parseInt(persistedRowsPerPage));
    } else {
      this.changeRowsPerPage(5);
    }
  };
}

export default Pagination;
