import { UseCase } from '@queries/globalTypes';
import { UseCaseLabels } from '@constants/useCaseLabes';

function matchRouteToUseCase(useCase: string) {
  switch (useCase) {
    case 'aud_dev':
      return UseCase.AudienceDevelopment;
    case 'sub':
      return UseCase.PaidSubscriptions;
    case 'brc':
      return UseCase.BrandedContent;
    case 'ads':
      return UseCase.AdRevenue;
    case 'eco':
      return UseCase.Ecommerce;
    case 'dsc':
      return UseCase.GoogleDiscovery;
    default:
      return UseCase.AudienceDevelopment;
  }
}

function matchUseCaseToRoute(useCase: UseCase) {
  switch (useCase) {
    case UseCase.AudienceDevelopment:
      return 'aud_dev';
    case UseCase.PaidSubscriptions:
      return 'sub';
    case UseCase.BrandedContent:
      return 'brc';
    case UseCase.AdRevenue:
      return 'ads';
    case UseCase.Ecommerce:
      return 'eco';
    case UseCase.GoogleDiscovery:
      return 'dsc';
  }
}

function matchUseCaseToLabel(useCase: UseCase) {
  switch (useCase) {
    case UseCase.AudienceDevelopment:
      return UseCaseLabels.AudienceDevelopment;
    case UseCase.PaidSubscriptions:
      return UseCaseLabels.PaidSubscriptions;
    case UseCase.BrandedContent:
      return UseCaseLabels.BrandedContent;
    case UseCase.AdRevenue:
      return UseCaseLabels.AdRevenue;
    case UseCase.Ecommerce:
      return UseCaseLabels.Ecommerce;
    case UseCase.GoogleDiscovery:
      return UseCaseLabels.GoogleDiscovery;
  }
}

function matchLabelToUseCase(useCase: UseCaseLabels) {
  switch (useCase) {
    case UseCaseLabels.AudienceDevelopment:
      return UseCase.AudienceDevelopment;
    case UseCaseLabels.PaidSubscriptions:
      return UseCase.PaidSubscriptions;
    case UseCaseLabels.BrandedContent:
      return UseCase.BrandedContent;
    case UseCaseLabels.AdRevenue:
      return UseCase.AdRevenue;
    case UseCaseLabels.Ecommerce:
      return UseCase.Ecommerce;
    case UseCaseLabels.GoogleDiscovery:
      return UseCase.GoogleDiscovery;
  }
}

export { matchRouteToUseCase, matchUseCaseToRoute, matchUseCaseToLabel, matchLabelToUseCase };
