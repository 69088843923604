import gql from 'graphql-tag';
import {
  VIDEO_VIEW_FRAGMENT,
  PIXEL_FRAGMENT,
  THRUPLAY_FRAGMENT,
  CONVERSIONS_FRAGMENT,
  REVENUE_FRAGMENT
} from '../fragments/adInsightsRow';

export const CAMPAIGNS_EXPORT_QUERY = gql`
  query CampaignsExportQuery(
    $id: ID!
    $dateRange: DateRangeInput!
    $campaignIds: [ID!]
    $conversionIds: [String!]
    $campaignTypes: [String!]
    $aggregationPeriod: AggregationPeriod!
    $useCases: [UseCase!]!
    $attributionWindow: AttributionWindow = default
    $ignoreRevenue: Boolean = true
  ) {
    adInsights(dateRange: $dateRange, campaignTypes: $campaignTypes, useCases: $useCases) {
      publisher(id: $id) {
        id
        name
        campaigns(ids: $campaignIds) {
          campaignType
          id
          name
          useCase
          firstDateOfSpend
          remainingBudget
          lifetimeSpend
          lifetimeBudget
          startDate
          endDate
          revenueRoiGoal @skip(if: $ignoreRevenue)
          insights(aggregationPeriod: $aggregationPeriod, attributionWindow: $attributionWindow) {
            dateInterval
            ctr
            spend
            impressions
            clicks
            clicksAll
            cpc
            ...VideoViewsFragment
            ...PixelFragment
            ...ThruplayFragment
            ...RevenueFragment
            ...ConversionsFragment
          }
        }
      }
    }
  }
  ${VIDEO_VIEW_FRAGMENT}
  ${PIXEL_FRAGMENT}
  ${THRUPLAY_FRAGMENT}
  ${CONVERSIONS_FRAGMENT}
  ${REVENUE_FRAGMENT}
`;
