import { PublisherInsightsQuery_adInsights_publisher_insights_conversions as Conversion } from '@queries/__generated__/PublisherInsightsQuery';
import DropdownItem from './DropdownItem';

export function isConversion(key: string) {
  return ['costPerAction', 'roas', 'aov', 'totalValue', 'totalActions'].some(sample => key.includes(sample));
}

export function createColumnsFromConversions<T extends { conversions: Conversion[] }>(
  insight: T,
  conversionFields: { [k: string]: [string, string[]] }
) {
  return Object.entries(conversionFields).reduce((columns: Record<string, number>, conversionField) => {
    const [key, conversionOptions] = conversionField;
    const options = conversionOptions[1];

    const conversionInInsight = insight ? insight.conversions.find(conversion => conversion.id === key) : null;
    options.forEach(option => {
      columns[`${key}+${option}`] = conversionInInsight
        ? conversionInInsight[option as keyof Omit<typeof conversionInInsight, 'id' | 'name'>]
        : 0;
    });
    return columns;
  }, {});
}

export function createDropdownItemFromConversions(conversions: { [k: string]: [string, string[]] }): DropdownItem[] {
  return Object.entries(conversions).reduce((conversionDdItems: DropdownItem[], conversionField) => {
    const [key, conversion] = conversionField;
    const [label, options] = conversion;
    options.forEach(option => {
      conversionDdItems.push(new DropdownItem(`${key}+${option}`, `${label}+${option}`));
    });
    return conversionDdItems;
  }, []);
}
