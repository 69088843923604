/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregationPeriod {
  DAY = "DAY",
  DAYS_28 = "DAYS_28",
  MONTH = "MONTH",
  SEVEN_DAYS = "SEVEN_DAYS",
  TOTAL = "TOTAL",
  WEEK = "WEEK",
}

export enum AttributionWindow {
  click_1d = "click_1d",
  click_28d = "click_28d",
  click_28d_view_1d = "click_28d_view_1d",
  click_7d = "click_7d",
  default = "default",
  view_1d = "view_1d",
  view_28d = "view_28d",
  view_7d = "view_7d",
}

export enum BrandRuleTypeEnum {
  flag = "flag",
  not_generate = "not_generate",
}

export enum ContentGenerationUseCaseEnum {
  create_new_content = "create_new_content",
  improve_time_spend_on_page = "improve_time_spend_on_page",
}

export enum CreativePreviewFormat {
  DESKTOP_FEED_STANDARD = "DESKTOP_FEED_STANDARD",
  FACEBOOK_STORY_MOBILE = "FACEBOOK_STORY_MOBILE",
  INSTAGRAM_REELS = "INSTAGRAM_REELS",
  INSTAGRAM_STANDARD = "INSTAGRAM_STANDARD",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  MESSENGER_MOBILE_INBOX_MEDIA = "MESSENGER_MOBILE_INBOX_MEDIA",
  MOBILE_FEED_STANDARD = "MOBILE_FEED_STANDARD",
  MOBILE_NATIVE = "MOBILE_NATIVE",
}

export enum CreativeReviewReason {
  bad_image = "bad_image",
  discard_story = "discard_story",
  other = "other",
  tone_and_style = "tone_and_style",
  typo_grammar = "typo_grammar",
  wrong_utm = "wrong_utm",
}

export enum CreativeReviewStatus {
  approved = "approved",
  disapproved = "disapproved",
  internally_disapproved = "internally_disapproved",
  need_of_client_approval = "need_of_client_approval",
  pending = "pending",
  pending_client_review = "pending_client_review",
  pending_client_review_and_upload = "pending_client_review_and_upload",
  pending_internal_approval = "pending_internal_approval",
  rejected = "rejected",
}

export enum CreativeSourceTypeEnum {
  keywee = "keywee",
  non_keywee = "non_keywee",
  none = "none",
}

export enum CreativeStatusEnum {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  IN_PROCESS = "IN_PROCESS",
  OVERDUE = "OVERDUE",
  PAUSED = "PAUSED",
  WITH_ISSUES = "WITH_ISSUES",
}

export enum GenderEnum {
  any = "any",
  female = "female",
  male = "male",
}

export enum GenerateIdeationsUseCaseEnum {
  generate_keyword_ideations_from_text = "generate_keyword_ideations_from_text",
  generate_story_headline_ideations_from_text = "generate_story_headline_ideations_from_text",
  generate_story_headline_ideations_from_urls = "generate_story_headline_ideations_from_urls",
}

export enum ImageTypeEnum {
  ai_image = "ai_image",
  article_image = "article_image",
  from_post = "from_post",
  manual = "manual",
}

export enum IndexSortMetricEnum {
  avg_eng_time_per_session = "avg_eng_time_per_session",
  bounce_rate = "bounce_rate",
  sessions = "sessions",
}

export enum IndexSourceEnum {
  ga = "ga",
  manual = "manual",
  pixel = "pixel",
}

export enum MetricKeys {
  HighestScalingStory = "HighestScalingStory",
  TopDemographicOfTopStory = "TopDemographicOfTopStory",
  TopEngagementCreative = "TopEngagementCreative",
  TopPerformingCampaign = "TopPerformingCampaign",
  TopPerformingCreative = "TopPerformingCreative",
  TopPerformingStory = "TopPerformingStory",
}

export enum UseCase {
  AdRevenue = "AdRevenue",
  AudienceDevelopment = "AudienceDevelopment",
  BrandedContent = "BrandedContent",
  Ecommerce = "Ecommerce",
  GoogleDiscovery = "GoogleDiscovery",
  PaidSubscriptions = "PaidSubscriptions",
}

export enum UserType {
  CSM = "CSM",
  Publisher = "Publisher",
}

export interface ArticleInput {
  url: string;
  title: string;
  description: string;
}

export interface AudienceAlignmentContentParamsInput {
  text?: string | null;
  keywords?: string[] | null;
  personaId: string;
  toneOfVoiceId?: string | null;
}

export interface BrandRuleInput {
  id?: string | null;
  term: string;
  type: BrandRuleTypeEnum;
  enabled: boolean;
  replaceTerm?: string | null;
  description: string;
}

export interface CommerceContentParamsInput {
  products: CommerceContentProductInput[];
  headline?: string | null;
  toneOfVoiceId?: string | null;
  personaId?: string | null;
}

export interface CommerceContentProductInput {
  name: string;
  description: string;
  isLong: boolean;
}

export interface ContentParamsInput {
  text: string;
  keywords?: string[] | null;
  wordsCount?: number | null;
  description?: string | null;
  headline?: string | null;
  domain?: string | null;
  toneOfVoiceId?: string | null;
  personaId?: string | null;
}

export interface CreativeReviewCommentsInput {
  generalComment?: string | null;
  postText?: string | null;
  headline?: string | null;
  description?: string | null;
}

export interface CreativeReviewFilterInput {
  publisherId: string;
  useCases: UseCase[];
  reviewStatuses: CreativeReviewStatus[];
  campaignIds?: string[] | null;
  storyIds?: string[] | null;
  reviewTime?: DateTimeRangeInput | null;
}

export interface DateRangeInput {
  startDate: any;
  endDate: any;
}

export interface DateTimeRangeInput {
  from?: any | null;
  to?: any | null;
}

export interface IndexConfigInput {
  file?: any | null;
  source: IndexSourceEnum;
  domain: string;
  sortMetric: IndexSortMetricEnum;
  discardedAt?: string | null;
}

export interface PersonaInput {
  id?: string | null;
  name: string;
  interests: string[];
  default: boolean;
  gender: GenderEnum;
  minAge: number;
  maxAge: number;
}

export interface ToneOfVoiceInput {
  id?: string | null;
  name: string;
  description: string;
  default?: boolean | null;
}

export interface UserPublisherPermissionsInput {
  publisherId: string;
  useCases: string[];
  manageApiAccess: boolean;
  receiveCreativeReviewEmails?: boolean | null;
  contentEnhancerAdmin?: boolean | null;
}

export interface WorkspaceParamsInput {
  name: string;
  reportsExternalUserIds?: string[] | null;
  domains: string[];
  indexConfigs?: IndexConfigInput[] | null;
  toneOfVoices: ToneOfVoiceInput[];
  personas: PersonaInput[];
  brandRules: BrandRuleInput[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
