import { IntrospectionResultData } from 'apollo-cache-inmemory';

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'FirstMetric',
        possibleTypes: [
          {
            name: 'HighestScalingStory'
          },
          {
            name: 'TopDemographicOfTopStory'
          },
          {
            name: 'TopEngagementCreative'
          },
          {
            name: 'TopPerformingCampaign'
          },
          {
            name: 'TopPerformingCreative'
          },
          {
            name: 'TopPerformingStory'
          }
        ]
      },
      {
        kind: 'UNION',
        name: 'SecondMetric',
        possibleTypes: [
          {
            name: 'HighestScalingStory'
          },
          {
            name: 'TopDemographicOfTopStory'
          },
          {
            name: 'TopEngagementCreative'
          },
          {
            name: 'TopPerformingCreative'
          },
          {
            name: 'TopPerformingStory'
          }
        ]
      }
    ]
  }
};
export default result;
