import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    marginTop: '60px',
    padding: '0 60px 0 118px'
  },
  headingTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  bodyTypography: {
    fontSize: '1.25rem'
  }
});
