import { FeaturesEnum } from '@services/Features';
import { store } from '@stores/index';
import { KeyweeFeatures } from '@services/KeyweeFeatures';
import { DEFAULT_KSS_FEATURES } from '@services/Features/DEFAULT_KSS_FEATURES';

// Keywee KSS localStorage key
const KW_KSS_FEATURES_KEY = 'KW_KSS_FEATURES';

// Keywee window namespace
class Keywee {
  static isExist: boolean;
  static instance: Keywee;

  static readonly FeaturesEnum = FeaturesEnum;

  readonly features = new KeyweeFeatures<FeaturesEnum>(
    KW_KSS_FEATURES_KEY,
    {
      add: store.userStore.addFeature,
      remove: store.userStore.removeFeature,
      setAll: store.userStore.addFeatures
    },
    DEFAULT_KSS_FEATURES
  );

  constructor() {
    if (Keywee.isExist) {
      return Keywee.instance;
    }

    Keywee.instance = this;
    Keywee.isExist = true;
  }
}

// Auto launch
(function (window: any) {
  window.Keywee = Keywee;
  window.kw = new Keywee();
  console.log('%c Keywee ', 'color: #fff; background: #638BD9;', window.kw);
})(window);
