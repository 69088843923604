import { isLocalHost } from '@utils/location';
import { kssSentryLogs } from '@services/kssSntryLogs';
import userEventLogger from '@queries/user/userEventLogger';
import Authentication from '@services/Authentication';

export const appErrorHandler = (error: Error) => {
  if (isLocalHost()) {
    return;
  }
  kssSentryLogs.exception(error);
  void userEventLogger.log({
    eventName: 'error',
    params: {
      error
    }
  });
};

export const appLogoutHandler = () => {
  Authentication.signOut();
  if (isLocalHost()) {
    return;
  }
  void userEventLogger.log({
    eventName: 'user_logout',
    params: {
      component: 'CatchAllErrorBoundary'
    }
  });
};
