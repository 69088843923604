import React, { Fragment } from 'react';
import { useStore } from '@stores/index';
import { observer } from 'mobx-react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const IsAuthenticated = ({ children }: Props) => {
  const { userStore } = useStore();
  return userStore.isAuthenticated ? <Fragment>{children}</Fragment> : null;
};

export default observer(IsAuthenticated);
