import { action, observable } from 'mobx';
import DropdownItem from '@utils/DropdownItem';
import { trackEvent } from '@services/MixPanel';
import { getKeysList } from '@utils/getKeysList';

function createMetrics(fields: string[]) {
  return fields.map(field => new DropdownItem(field));
}

const initialMetrics = [new DropdownItem('clicks')];

class AgeGenderBreakdown {
  @observable allMetrics = initialMetrics;
  @observable metrics = initialMetrics;
  @action initializeMetrics = (data: any, filterMetrics: string[], initialMetric1?: DropdownItem<string>) => {
    this.allMetrics = [
      new DropdownItem('', 'Choose value'),
      ...createMetrics(getKeysList(data.gender[0], ['key', 'cpc', 'ctr']))
    ]
      .filter(metric => !filterMetrics.includes(metric.value))
      .filter(metric => !metric.value.toLowerCase().includes('sailthru'))
      .filter(metric => !metric.value.toLowerCase().includes('facebook lead'))
      .filter(metric => !metric.value.toLowerCase().endsWith('+costperaction'));
    const [metric1] = this.metrics;

    this.metrics[0] = initialMetric1 || metric1;
  };
  @action changeMetric = (metricIndex: number) => (metric: DropdownItem) => {
    trackEvent('age_gender_breakdown_metric_change', {
      metricIndex,
      metric: metric.value
    });
    this.metrics[metricIndex] = metric;
  };
}
export default AgeGenderBreakdown;
