import { observable, action } from 'mobx';
import { PermissionsQuery_me_permissions_useCases as UseCase } from '@queries/__generated__/PermissionsQuery';

class Permissions {
  @observable hasApiAccess = false;
  @observable contentEnhancerAdmin = false;
  @observable useCases: UseCase[] = [];
  @action setUseCases(useCases: UseCase[]) {
    this.useCases = useCases;
  }
}

export default Permissions;
