import gql from 'graphql-tag';
import {
  VIDEO_VIEW_FRAGMENT,
  CONVERSIONS_FRAGMENT,
  PIXEL_FRAGMENT,
  THRUPLAY_FRAGMENT
} from '../fragments/adInsightsRow';

export const CREATIVES_EXPORT_QUERY = gql`
  query CreativesExportQuery(
    $id: ID!
    $storyIds: [ID!]
    $dateRange: DateRangeInput!
    $conversionIds: [String!]
    $campaignIds: [ID!]
    $campaignTypes: [String!]
    $useCases: [UseCase!]!
    $attributionWindow: AttributionWindow = default
  ) {
    adInsights(dateRange: $dateRange, campaignTypes: $campaignTypes, useCases: $useCases) {
      publisher(id: $id) {
        id
        campaigns(ids: $campaignIds) {
          id
          name
          useCase
          stories(ids: $storyIds) {
            id
            title
            fullUrl
            creatives(thumbnailSize: { height: 100, width: 100 }) {
              id
              facebookPostId
              thumbnailUrl
              imageType
              isVideo
              isPolitical
              postText
              description
              callToAction
              linkHeadline
              creativeTemplateSourceType
              linkCaption
              fullUrl
              insights(
                aggregationPeriod: TOTAL
                attributionWindow: $attributionWindow
                aggregateByCreativeType: false
              ) {
                dateInterval
                spend
                clicks
                clicksAll
                cpc
                ctr
                impressions
                ...VideoViewsFragment
                ...ConversionsFragment
                ...PixelFragment
                ...ThruplayFragment
              }
            }
          }
        }
      }
    }
  }
  ${VIDEO_VIEW_FRAGMENT}
  ${CONVERSIONS_FRAGMENT}
  ${PIXEL_FRAGMENT}
  ${THRUPLAY_FRAGMENT}
`;
