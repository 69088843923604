import gql from 'graphql-tag';
import {
  VIDEO_VIEW_FRAGMENT,
  PIXEL_FRAGMENT,
  THRUPLAY_FRAGMENT,
  CONVERSIONS_FRAGMENT,
  REVENUE_FRAGMENT
} from '../fragments/adInsightsRow';

export const STORIES_EXPORT_QUERY = gql`
  query StoriesExportQuery(
    $id: ID!
    $dateRange: DateRangeInput!
    $conversionIds: [String!]
    $campaignIds: [ID!]
    $storyIds: [ID!]
    $campaignTypes: [String!]
    $aggregationPeriod: AggregationPeriod!
    $useCases: [UseCase!]!
    $attributionWindow: AttributionWindow = default
    $ignoreRevenue: Boolean = false
  ) {
    adInsights(dateRange: $dateRange, campaignTypes: $campaignTypes, useCases: $useCases) {
      publisher(id: $id) {
        id
        name
        campaigns(ids: $campaignIds) {
          id
          name
          useCase
          firstDateOfSpend
          startDate
          endDate
          campaignType
          stories(ids: $storyIds) {
            id
            title
            url
            firstDateOfSpend
            numOfSpendingCreatives
            insights(aggregationPeriod: $aggregationPeriod, attributionWindow: $attributionWindow) {
              dateInterval
              ctr
              spend
              impressions
              clicks
              clicksAll
              cpc
              ...VideoViewsFragment
              ...ThruplayFragment
              ...PixelFragment
              ...RevenueFragment
              ...ConversionsFragment
            }
          }
        }
      }
    }
  }
  ${VIDEO_VIEW_FRAGMENT}
  ${THRUPLAY_FRAGMENT}
  ${PIXEL_FRAGMENT}
  ${CONVERSIONS_FRAGMENT}
  ${REVENUE_FRAGMENT}
`;
