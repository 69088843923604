import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './errorBoundary.styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Link from '@material-ui/core/Link';

const BrowserSupportError = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography classes={{ h2: classes.headingTitle }} variant="h2" align="center">
        {' '}
        <WarningRoundedIcon fontSize="inherit" htmlColor="#ff9800" /> Oh-oh
      </Typography>
      <Typography classes={{ body1: classes.bodyTypography }}>This browser is not supported.</Typography>
      <Typography classes={{ body1: classes.bodyTypography }}>
        We support the current versions of <Link href="https://www.google.com/chrome/">Google Chrome</Link>,{' '}
        <Link href="http://www.mozilla.org/firefox/">Firefox</Link>,{' '}
        <Link href="http://www.apple.com/safari/">Safari</Link>, and{' '}
        <Link href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</Link>.
      </Typography>
    </div>
  );
};

export default BrowserSupportError;
