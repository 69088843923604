import { observable, action, computed } from 'mobx';
import DropdownItem from '@utils/DropdownItem';
import { AttributionWindow } from '@queries/globalTypes';
import { attributionWindowLabels } from '@constants/attributionWindow';
import { trackEvent } from '@services/MixPanel';

const initialValue = new DropdownItem(AttributionWindow.default, attributionWindowLabels[AttributionWindow.default]);

class AttributionWindowStore {
  isViewed = false;
  private _keyToSaveTo = '';
  private _domainKey = 'attributionWindow';
  @observable private _attributionWindow: DropdownItem<string> = initialValue;
  @computed get rawValue() {
    return this._attributionWindow;
  }
  @computed get value() {
    return this._attributionWindow.value;
  }
  @action change = (value: DropdownItem) => {
    this._attributionWindow = value;
    trackEvent('attribution_window_change', { value });
    localStorage.setItem(this._keyToSaveTo, JSON.stringify(value));
  };
  @action initialize = (fromKey: string) => {
    this.isViewed = true;
    this._keyToSaveTo = `${fromKey}.${this._domainKey}`;
    const persistedValue = localStorage.getItem(this._keyToSaveTo);
    if (persistedValue) {
      this._attributionWindow = JSON.parse(persistedValue);
    }
  };
}

export default AttributionWindowStore;
