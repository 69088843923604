import Reports from './pages/Reports';
import { AggregationPeriod } from '@queries/globalTypes';
import { CAMPAIGNS_EXPORT_QUERY } from '@queries/exportQueries/campaignsExport';
import { STORIES_EXPORT_QUERY } from '@queries/exportQueries/storiesExport';
import Authentication from '@services/Authentication';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import fileDownload from 'js-file-download';
import { matchRouteToUseCase } from '@utils/transformUseCase';
import { CREATIVES_EXPORT_QUERY } from '@queries/exportQueries/creativesExport';
import { trackEvent } from '@services/MixPanel';
import { API_URL } from '../config';

function stringifyGQLQuery(query: any) {
  return query.loc.source.body;
}

type ExportConfiguration =
  | {
      type: 'campaigns';
      level: 'by_day';
    }
  | {
      type: 'campaigns';
      level: 'by_week';
    }
  | {
      type: 'campaigns';
      level: 'total';
    }
  | {
      type: 'stories';
      level: 'by_day';
    }
  | {
      type: 'stories';
      level: 'by_week';
    }
  | {
      type: 'stories';
      level: 'total';
    }
  | {
      type: 'creatives';
      level: 'total';
    };

type GetCSV = {
  configuration: ExportConfiguration;
  startCb: () => void;
  successCb: () => void;
  failureCb: () => void;
};

class Export {
  _rootStore: Reports;

  private getQuery = (type: string) => {
    switch (type) {
      case 'campaigns':
        return stringifyGQLQuery(CAMPAIGNS_EXPORT_QUERY);
      case 'stories':
        return stringifyGQLQuery(STORIES_EXPORT_QUERY);
      case 'creatives':
        return stringifyGQLQuery(CREATIVES_EXPORT_QUERY);
      default:
        throw new Error('Invalid type for Export');
    }
  };

  private getAggregationPeriod = (level: string) => {
    switch (level) {
      case 'by_day':
        return AggregationPeriod.DAY;
      case 'by_week':
        return AggregationPeriod.SEVEN_DAYS;
      case 'total':
        return AggregationPeriod.TOTAL;
      default:
        throw new Error('Invalid level for Export');
    }
  };

  private getOperationName = (type: string) => {
    switch (type) {
      case 'campaigns':
        return 'CampaignsExportQuery';
      case 'stories':
        return 'StoriesExportQuery';
      case 'creatives':
        return 'CreativesExportQuery';
      default:
        throw new Error('Invalid type for Export');
    }
  };

  constructor(rootStore: Reports) {
    this._rootStore = rootStore;
  }

  private getVariables = (configuration: ExportConfiguration) => {
    const currentUseCase = this._rootStore._rootStore.name;
    if (configuration.type === 'campaigns' || configuration.type === 'stories') {
      return {
        id: this._rootStore._rootStore._rootStore.userStore.publisher?.id ?? null,
        dateRange: this._rootStore.datepicker.value.dateRange,
        conversionIds: this._rootStore.conversionIds,
        campaignTypes: this._rootStore.campaignTypeFilter.value,
        campaignIds: this._rootStore.campaignSelection.value,
        storyIds: this._rootStore.storySelection.value,
        aggregationPeriod: this.getAggregationPeriod(configuration.level),
        attributionWindow: this._rootStore.attributionWindow.value,
        useCases: [matchRouteToUseCase(currentUseCase)],
        ignoreRevenue: currentUseCase !== 'ads'
      };
    } else {
      return {
        id: this._rootStore._rootStore._rootStore.userStore.publisher?.id ?? null,
        storyIds: this._rootStore.storySelection.value,
        dateRange: this._rootStore.datepicker.value.dateRange,
        conversionIds: this._rootStore.conversionIds,
        campaignTypes: this._rootStore.campaignTypeFilter.value,
        campaignIds: this._rootStore.campaignSelection.value,
        attributionWindow: this._rootStore.attributionWindow.value,
        useCases: [matchRouteToUseCase(this._rootStore._rootStore.name)]
      };
    }
  };

  getCSV = ({ configuration, startCb, successCb, failureCb }: GetCSV) => {
    trackEvent('export_csv', {
      publisherId: this._rootStore._rootStore._rootStore.userStore.publisher?.id,
      publisherName: this._rootStore._rootStore._rootStore.userStore.publisher?.name,
      type: configuration.type,
      aggregation: configuration.level,
      start: format(this._rootStore.datepicker.value.dateRange.startDate, 'YYYY-MM-DD'),
      end: format(this._rootStore.datepicker.value.dateRange.endDate, 'YYYY-MM-DD'),
      totalDays: differenceInCalendarDays(
        this._rootStore.datepicker.value.dateRange.startDate,
        this._rootStore.datepicker.value.dateRange.endDate
      )
    });
    startCb();
    let filename = '';
    fetch(`${API_URL}/graphql/reports`, {
      method: 'POST',
      headers: {
        'X-Access-Token': Authentication.getToken()!,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: this.getQuery(configuration.type),
        variables: this.getVariables(configuration),
        operationName: this.getOperationName(configuration.type),
        hierarchy: configuration.type,
        level: configuration.level,
        from_date: format(this._rootStore.datepicker.value.dateRange.startDate, 'YYYY-MM-DD'),
        to_date: format(this._rootStore.datepicker.value.dateRange.endDate, 'YYYY-MM-DD')
      })
    })
      .then(res => {
        if (res.status === 200 && res.headers.get('Content-Type') === 'text/csv') {
          const header = res.headers.get('Content-Disposition') || '';
          const fileNameMatch = header.match(/filename="(.+)"/);
          filename = fileNameMatch && fileNameMatch.length > 0 ? fileNameMatch[1] : '';
          return res.blob();
        }
        return;
      })
      .then(result => {
        if (result) {
          successCb();
          fileDownload(result, filename);
        }
      })
      .catch(() => {
        failureCb();
      });
  };
}

export default Export;
