import { MeQuery_me, MeQuery_me_publishers } from '@queries/__generated__/MeQuery';
import { action, observable } from 'mobx';
import RootStore, { useStore } from './index';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { trackEvent } from '@services/MixPanel';
import userEventLogger from '@queries/user/userEventLogger';
import { FeaturesEnum } from '@services/Features';
import { kssSentryLogs } from '@services/kssSntryLogs';

export const KSS_ACCESS_TOKEN = 'kssAccessToken';

type TabHistory = Record<string, string>;

class User {
  _rootStore: RootStore;
  private _keyToSaveTo = '';
  private _publisherInitialized = false;
  @observable user: MeQuery_me | null = null;
  @observable isAuthenticated = Boolean(localStorage.getItem(KSS_ACCESS_TOKEN));
  @observable features: FeaturesEnum[] = [];
  @observable publisher: MeQuery_me_publishers | null = null;
  @observable adBlockerOn = false;
  @observable tabHistory: TabHistory = {
    aud_dev: 'overview',
    sub: 'reports',
    brc: 'reports',
    ads: 'reports',
    eco: 'reports'
  };
  @action initializeUser(me: MeQuery_me) {
    userEventLogger.setUser(me.email);

    kssSentryLogs.setUser({
      id: me.id,
      email: me.email
    });

    if (process.env.NODE_ENV === 'production') {
      mixpanel.people.set({
        $email: me.email,
        is_csm: me.userType === 'CSM'
      });
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: me.email });
      });
    }
    me.publishers = me.publishers.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    this.user = me;
  }
  @action initializePublisher = (fromKey: string, defaultPublisher: string, initialPublisher?: string | null) => {
    if (this._publisherInitialized) {
      return;
    }
    this._keyToSaveTo = `${fromKey}.publisher`;
    if (initialPublisher) {
      if (
        this.user?.publishers &&
        this.user?.publishers.findIndex(publisher => publisher.id == initialPublisher) >= 0
      ) {
        this.changePublisher(initialPublisher);
        return;
      }
      this.changePublisher(defaultPublisher);
      return;
    }
    const persistedPublisher = localStorage.getItem(this._keyToSaveTo);
    this.changePublisher(persistedPublisher ? persistedPublisher.replace(/['"]/g, '') : defaultPublisher);
  };
  @action changePublisher = (publisherId: string) => {
    const publisherByName = this.user?.publishers.find(pub => pub.id === publisherId);

    kssSentryLogs.setContext('Publisher', {
      id: publisherByName?.id,
      name: publisherByName?.name
    });

    userEventLogger.setPublisher(Number(publisherByName?.id));

    if (process.env.NODE_ENV === 'production') {
      mixpanel.register({
        publisherId: publisherByName?.id ?? '',
        publisherName: publisherByName?.name ?? ''
      });
      trackEvent('publisher_change');
      Sentry.configureScope(function (scope) {
        scope.setExtra('publisher', publisherId);
      });
    }
    this.publisher = publisherByName ?? null;
    localStorage.setItem(this._keyToSaveTo, publisherId);
  };
  @action authenticate = () => {
    this.isAuthenticated = true;
  };

  /*
  Features
   */
  @action setFeatures = (features: FeaturesEnum[]) => {
    this.features = [...features];
  };
  @action addFeature = (feature: FeaturesEnum) => {
    if (this.features.indexOf(feature) === -1) {
      this.features = [...this.features, feature];
    }
  };
  @action addFeatures = (features: FeaturesEnum[]) => {
    this.features = [...this.features, ...features.filter(feature => this.features.indexOf(feature) === -1)];
  };
  @action removeFeature = (feature: FeaturesEnum) => {
    this.features = this.features.filter(f => f !== feature);
  };
  getFeatures() {
    return [...this.features];
  }

  @action changeTab = (page: keyof TabHistory, tab: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (this.tabHistory.hasOwnProperty(page)) {
      this.tabHistory[page] = tab;
    } else {
      throw new Error('The page used is not part of the application');
    }
  };
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }
}

export const useUser = () => {
  const store = useStore();
  if (!store.userStore.user) throw new Error('User has not been initialized');
  return store.userStore.user;
};

export const useUserStore = () => {
  const store = useStore();
  if (!store.userStore) throw new Error('User has not been initialized');
  return store.userStore;
};

export const useSetAdBlocker = () => {
  const store = useStore();
  if (!store.userStore.user) throw new Error('User has not been initialized');
  return (adBlockerState: boolean) => {
    store.userStore.adBlockerOn = adBlockerState;
  };
};

export const useAdBlockerOn = () => {
  const store = useStore();
  if (!store.userStore.user) throw new Error('User has not been initialized');
  return store.userStore.adBlockerOn;
};

export const usePublisher = () => {
  const store = useStore();
  if (!store.userStore.user) throw new Error('User has not been initialized');
  if (!store.userStore.publisher) throw new Error('Publisher has not been initialized');
  return store.userStore.publisher;
};

export default User;
