import { observable, action, computed } from 'mobx';
import DropdownItem from '@utils/DropdownItem';
import { trackEvent } from '@services/MixPanel';
import Reports from './pages/Reports';

const initialMetrics = [
  new DropdownItem('spend'),
  new DropdownItem('clicks'),
  new DropdownItem('cpc'),
  new DropdownItem('ctr')
];

class Creative {
  @observable metrics = initialMetrics;
  @observable _sortBy = 0;
  @observable sortOrder: 'asc' | 'desc' = 'desc';
  _rootStore: Reports;
  constructor(rootStore: Reports) {
    this._rootStore = rootStore;
  }
  @action reseteCreativeMetrics = () => {
    this.metrics = initialMetrics;
  };
  @action changeMetric = (metricIndex: number) => (metric: DropdownItem) => {
    this.metrics[metricIndex] = metric;
    trackEvent('creative_panel_change_metric', {
      metric: metric.value
    });
  };
  @action changeSortBy = (metric: DropdownItem) => {
    const index = this.metrics.findIndex(option => option.value === metric.value);
    if (index === this._sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }
    this._sortBy = index;
  };
  @computed get sortBy() {
    return this.metrics[this._sortBy];
  }
}

export default Creative;
