import * as Sentry from '@sentry/react';
import { ScopeContext, User } from '@sentry/types';
import { Integrations } from '@sentry/tracing';

export type LogsScopeContext = Partial<ScopeContext>;
export type LogsUser = User | null;

const getScope = (context1: any, context2?: LogsScopeContext) => {
  return {
    app: context1.dsn,
    environment: context1.environment,
    release: context1.release,
    host: window.location.host,
    href: window.location.href,
    ...(context2 || {})
  };
};

export class SentryLogs {
  static isExist: boolean;
  static instance: SentryLogs;

  constructor(
    readonly dsn: string,
    readonly environment: 'development' | 'production',
    readonly release: string
  ) {
    if (SentryLogs.isExist) {
      return SentryLogs.instance;
    }

    SentryLogs.instance = this;
    SentryLogs.isExist = true;

    console.log('%c Init Sentry ', 'color: #fff; background: #638BD9;', process.env.NODE_ENV);
    if (this.isDevelopment) return;
    Sentry.init({
      dsn,
      environment,
      release,
      debug: false,

      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });
  }

  setUser(user: LogsUser) {
    if (this.isDevelopment) return;
    Sentry.setUser(user);
  }

  setContext(
    name: string,
    context: {
      [key: string]: any;
    } | null
  ) {
    if (this.isDevelopment) return;
    Sentry.setContext(name, context);
  }

  exception(error: Error, scope?: LogsScopeContext) {
    console.log('%c SentryLogs Error: ', 'color: #fff; background: #EF05AC;', error, scope);
    if (this.isDevelopment) return;
    Sentry.captureException(error, getScope(this, scope));
  }

  message(message: string, scope?: LogsScopeContext) {
    console.log('%c SentryLogs Message: ', 'color: #fff; background: #423b87;', message, scope);
    if (this.isDevelopment) return;
    Sentry.captureMessage(message, getScope(this, scope));
  }

  private get isDevelopment() {
    return this.environment === 'development';
  }
}
