type FeaturesMap = {
  [key: string]: boolean;
};

interface Store<T> {
  add: (feature: T) => void;
  remove: (feature: T) => void;
  setAll: (features: T[]) => void;
}

const KW_FEATURES_KEY = 'KW_FEATURES';

export class KeyweeFeatures<FeaturesEnum extends string> {
  static isExist: boolean;
  static instance: KeyweeFeatures<any>;

  private readonly map: FeaturesMap = {};

  constructor(
    private readonly key: string = KW_FEATURES_KEY,
    private readonly store: Store<FeaturesEnum>,
    private readonly defaultState: FeaturesMap
  ) {
    if (KeyweeFeatures.isExist) {
      return KeyweeFeatures.instance;
    }

    KeyweeFeatures.instance = this;
    KeyweeFeatures.isExist = true;

    this.readLocalStorage();
  }

  add(feature: FeaturesEnum): void {
    this.map['' + feature] = true;
    this.store.add(feature);
    this.saveLocalStorage();
  }

  remove(feature: FeaturesEnum): void {
    this.map['' + feature] = false;
    this.store.remove(feature);
    this.saveLocalStorage();
  }

  getList(): FeaturesEnum[] {
    return Object.keys(this.map)
      .filter(key => this.map[key])
      .map(key => key as FeaturesEnum);
  }

  clear(): void {
    localStorage.removeItem(this.key);
    this.readLocalStorage();
  }

  private restoreDefaultData(data: FeaturesMap) {
    Object.keys(data).forEach((feature: keyof FeaturesMap) => {
      this.map[feature] = data[feature];
    });
    this.store.setAll(this.getList());
  }

  private readLocalStorage() {
    const data = localStorage.getItem(this.key);
    if (!data) {
      return this.restoreDefaultData(this.defaultState);
    }
    try {
      const storedData = JSON.parse(data);
      return this.restoreDefaultData(storedData);
    } catch (error) {
      console.error(error);
    }
  }

  private saveLocalStorage() {
    const data = JSON.stringify(this.map);
    localStorage.setItem(this.key, data);
  }
}
