import { fieldToLabelMap } from '@constants/fields';
import { isConversion } from '@utils/conversion';

function generateConversionLabel(key: string) {
  if (isConversion(key)) {
    const [parentKey, childKey] = key.split('+');
    switch (childKey) {
      case 'costPerAction':
        return 'Cost per ' + parentKey;
      case 'roas':
      case 'aov':
      case 'totalValue':
        return parentKey + ' ' + childKey;
      default:
        return parentKey;
    }
  }
  return key;
}

export function getName(key: string) {
  if (key in fieldToLabelMap) {
    return fieldToLabelMap[key];
  }
  return generateConversionLabel(key);
}
