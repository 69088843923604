import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import GenericErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import { BaseHeader } from '../../../components/BaseHeader';
import { PageWrapper } from '@components/PageWrapper2';
import LogoutWarning from '../../../dialogs/LogoutWarning';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  onException: (error: Error) => void;
  onLogout: () => void;
}

interface State {
  hasErrors: boolean;
  showLogoutWarning: boolean;
}

export class CatchAllErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasErrors: false, showLogoutWarning: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (error.name === 'ChunkLoadError') {
      return { showLogoutWarning: true };
    }
    return { hasErrors: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (error.name !== 'ChunkLoadError') {
      this.props.onException(error);
      console.error(error, info);
    }
  }

  private toggleLogoutWarning = () => {
    this.setState(state => {
      return {
        showLogoutWarning: !state.showLogoutWarning
      };
    });
  };

  render() {
    if (this.state.hasErrors) {
      return (
        <React.Fragment>
          <CssBaseline />
          <PageWrapper>
            <BaseHeader />
            <GenericErrorBoundary />
          </PageWrapper>
        </React.Fragment>
      );
    }
    if (this.state.showLogoutWarning) {
      return (
        <LogoutWarning
          open={this.state.showLogoutWarning}
          handleClose={this.toggleLogoutWarning}
          onLogin={this.props.onLogout}
        />
      );
    }
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
