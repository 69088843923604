import { observable, action } from 'mobx';

type Count = { in: number; out: number };

class SelectionCounter {
  @observable selectedCampaignsCount: Count = { out: 0, in: 0 };
  @action changeSelectedCampaignsCount = (count: Count) => {
    this.selectedCampaignsCount = count;
  };

  @observable selectedStoriesCount: Count = { out: 0, in: 0 };
  @action changeSelectedStoriesCount = (count: Count) => {
    this.selectedStoriesCount = count;
  };

  @action reset = () => {
    this.selectedCampaignsCount = { out: 0, in: 0 };
    this.selectedStoriesCount = { out: 0, in: 0 };
  };
}
export default SelectionCounter;
