import client from '../../apollo';
import gql from 'graphql-tag';
import { events } from '@services/MixPanel/events';

const LOG_USER_EVENT_MUTATION = gql`
  mutation LogUserEvent($email: String!, $eventName: String!, $publisherId: Int, $parameters: JSON!) {
    logUserEvent(email: $email, eventName: $eventName, publisherId: $publisherId, parameters: $parameters) {
      errors
    }
  }
`;

class UserEventLogger {
  email = '';
  publisherId?: number;

  async log(options: { eventName: keyof typeof events; params?: any }) {
    try {
      // console.info(`logging event ${options.eventName} | user: ${this.email} | publisherId: ${this.publisherId}`);
      await client.mutate({
        mutation: LOG_USER_EVENT_MUTATION,
        variables: {
          email: this.email,
          eventName: options.eventName,
          publisherId: this.publisherId,
          parameters: options.params || {}
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async setUser(email: string) {
    // for some reason this function is called twice in succession
    // so need to make sure we're not logging the same event again
    if (this.email != email) {
      this.email = email;
      await this.log({ eventName: 'user_login' });
    }
  }

  async setPublisher(publisherId?: number) {
    if (this.publisherId != publisherId) {
      this.publisherId = publisherId;
      await this.log({ eventName: 'publisher_change' });
    }
  }
}

const userEventLogger = new UserEventLogger();

export default userEventLogger;
