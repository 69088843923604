import gql from 'graphql-tag';

export const VIDEO_VIEW_FRAGMENT = gql`
  fragment VideoViewsFragment on AdInsightsRow {
    __typename
    videoView
    costPerVideoView
    videoPlay
    costPerVideoPlay
    videoP25WatchedActions
    videoP25CostPerWatchedAction
    videoP50WatchedActions
    videoP50CostPerWatchedAction
    videoP75WatchedActions
    videoP75CostPerWatchedAction
    videoP100WatchedActions
    videoP100CostPerWatchedAction
  }
`;

export const PIXEL_FRAGMENT = gql`
  fragment PixelFragment on AdInsightsRow {
    __typename
    pixelSessions
    pixelCostPerPageView
    pixelAveragePageViewsPerSession
  }
`;

export const THRUPLAY_FRAGMENT = gql`
  fragment ThruplayFragment on AdInsightsRow {
    __typename
    videoThruplayWatchedActions
    videoThruplayCostPerWatchedAction
  }
`;

export const CONVERSIONS_FRAGMENT = gql`
  fragment ConversionsFragment on AdInsightsRow {
    __typename
    conversions(ids: $conversionIds) {
      id
      aov
      roas
      costPerAction
      totalActions
      totalValue
    }
  }
`;

export const REVENUE_FRAGMENT = gql`
  fragment RevenueFragment on AdInsightsRow {
    __typename
    pixelPageViews @skip(if: $ignoreRevenue)
    revenue @skip(if: $ignoreRevenue)
    profit @skip(if: $ignoreRevenue)
    revenueRoi @skip(if: $ignoreRevenue)
  }
`;

export const AGE_GENDER_BREAKDOWN_FRAGMENT = gql`
  fragment AgeGenderBreakdownFragment on AdInsightsRow {
    __typename
    breakdowns {
      age {
        key
        clicks
        clicksAll
        impressions
        spend
        conversions(ids: $conversionIds) {
          id
          aov
          roas
          costPerAction
          totalActions
          totalValue
        }
      }
      gender {
        key
        clicks
        clicksAll
        impressions
        spend
        conversions(ids: $conversionIds) {
          id
          aov
          roas
          costPerAction
          totalActions
          totalValue
        }
      }
    }
  }
`;
