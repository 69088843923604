import { observable, action } from 'mobx';

class Search {
  @observable value = '';

  @action search = (value: string): void => {
    this.value = value;
  };
}

export default Search;
