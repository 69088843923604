import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { useStyles } from './loader.styles';

type Props = CircularProgressProps;

export const Loader: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  );
};
