import { createTheme } from '@material-ui/core/styles';

export const primary = {
  main: '#638BD9'
};

export const secondary = {
  main: '#606C78'
};

export const warning = {
  main: '#ff9800'
};
export const danger = {
  main: '#f44336'
};

export const green = '#47B972';

export const text = {
  primary: '#202528',
  secondary: '#606C78'
};

export const divider = '#E2E7EA';
export const standardBorder = `1px solid ${divider}`;

const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro, Helvetica, Arial, sans-serif',
    fontSize: 15
  },
  shape: {
    borderRadius: 8
  },
  palette: {
    primary: {
      main: primary.main
    },
    secondary: {
      main: secondary.main
    },
    text: {
      primary: text.primary,
      secondary: text.secondary
    },
    background: {
      default: '#fff'
    },
    divider
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 1px 1px #E2E7EA'
      }
    },
    MuiTable: {
      root: {
        display: 'flex',
        flex: '1 0 700px',
        flexDirection: 'column',
        borderTop: standardBorder,
        borderLeft: standardBorder,
        borderRight: standardBorder
      }
    },
    MuiTableHead: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: '0',
        zIndex: 3,
        '& th': {
          fontSize: '14px',
          color: '#11131E',
          whiteSpace: 'normal',
          background: '#fff'
        },
        '& tr:first-child th': {
          color: text.secondary,
          fontWeight: 400
        },
        '& tr:nth-of-type(2) th': {
          color: primary.main
        }
      }
    },
    MuiTableBody: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        '& tr td': {
          fontSize: '14px',
          color: text.secondary,
          backgroundColor: '#fff'
        },
        '& tr:nth-child(odd) td': {
          backgroundColor: '#F9FAFB'
        }
      }
    },
    MuiTableRow: {
      root: {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    MuiTableCell: {
      root: {
        display: 'flex',
        boxSizing: 'content-box',
        alignItems: 'center',
        flex: '1 0 auto',
        paddingRight: '16px',
        padding: '8px',
        borderRight: standardBorder,
        '&:last-child': {
          borderRight: 'none'
        },
        borderBottom: standardBorder
      }
    },
    MuiTooltip: {
      tooltip: {
        padding: '15px',
        backgroundColor: '#fff',
        color: text.primary,
        boxShadow: 'rgba(0,0,0,0.3) 0 2px 10px',
        fontSize: '14px',
        fontWeight: 400
      }
    }
  }
});

export default theme;
