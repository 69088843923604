import React from 'react';
import useStyles from './pageWrapper.styles';
import { WidthProperty } from 'csstype';

const PAGE_WIDTH = 1370;

interface Props {
  children: React.ReactNode;
  width?: WidthProperty<string | 0>;
}

export const PageWrapper: React.FC<Props> = ({ children, width }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container} style={{ width: width || `${PAGE_WIDTH}px` }}>
        {children}
      </div>
    </div>
  );
};
