import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './errorBoundary.styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Link from '@material-ui/core/Link';

function GenericErrorBoundary() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography classes={{ h2: classes.headingTitle }} variant="h2" align="center">
        {' '}
        <WarningRoundedIcon fontSize="inherit" htmlColor="#ff9800" /> Oh-oh
      </Typography>
      <Typography classes={{ body1: classes.bodyTypography }}>
        Something went wrong on our end. Our engineers have been notified and are working to resolve the issue. Sorry
        about that.
      </Typography>
      <Typography classes={{ body1: classes.bodyTypography }}>
        Please try to <Link href="/login">login</Link> again or contact your Customer Success Manager.
      </Typography>
    </div>
  );
}
export default GenericErrorBoundary;
