import { action, observable } from 'mobx';
import { createContext, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../config';

const emailRegexp = new RegExp(
  /* eslint-disable-next-line */
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

class ResetPassword {
  @observable email = '';
  @observable emailError = '';
  @observable submitting = false;

  @action insertEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.email = event.target.value;
    if (this.validateEmail()) {
      this.emailError = '';
    }
  };

  @action validateEmail = () => {
    const { email } = this;
    if (!email.length) {
      this.emailError = 'Please enter email address';
    } else if (emailRegexp.test(email.trim())) {
      this.emailError = '';
      return true;
    } else {
      this.emailError = 'Please enter a valid email address';
    }
  };

  @action resetPassword = (email: string) => {
    this.submitting = true;
    axios({
      url: `${API_URL}/authentication/reset_password`,
      method: 'POST',
      data: {
        email: email
      }
    })
      .then((response: any) => {
        if (response.status === 200) {
          this.submitting = false;
        }
      })
      .catch(error => {
        this.submitting = true;
        if (error.response?.data?.error) {
          this.emailError = error.response.data.error;
        } else {
          this.emailError = 'Something when wrong, please try again or contact your CSM';
        }
      });
  };
}

const ResetPasswordContext = createContext(new ResetPassword());

export const useResetPasswordCStore = () => useContext(ResetPasswordContext);

export default ResetPassword;
