import RootStore from './index';
import Overview from './pages/Overview';
import Reports from './pages/Reports';
import { autorun, reaction } from 'mobx';
import { DatePresetsEnum } from '@constants/DatePresets';
import difference_in_calendar_days from 'date-fns/difference_in_calendar_days';
import { trackEvent } from '@services/MixPanel';

interface Pages {
  overview?: boolean;
  reports: boolean;
}

interface Config {
  name: string;
  pages: Pages;
  withAttributionWindow?: boolean;
  omittedColumns: string[];
}

class UseCase {
  _rootStore: RootStore;
  name: string;
  pages: Pages;
  overview?: Overview;
  reports: Reports;
  withAttributionWindow: boolean;
  omittedColumns: string[];
  constructor(rootStore: RootStore, config: Config) {
    const { name, pages, omittedColumns, withAttributionWindow = false } = config;
    this.name = name;
    this._rootStore = rootStore;
    this.pages = pages;
    if (pages.overview) {
      this.overview = new Overview();
    }
    this.reports = new Reports(this);
    this.withAttributionWindow = withAttributionWindow;
    this.omittedColumns = omittedColumns;
    reaction(
      () => this.reports.datepicker.value,
      date => {
        trackEvent('date_picker_change', {
          tab: 'reports',
          start: date.dateRange.startDate,
          end: date.dateRange.endDate,
          totalDays: difference_in_calendar_days(date.dateRange.startDate, date.dateRange.endDate)
        });
      }
    );
    reaction(
      () => this.overview?.datepicker.value,
      date => {
        trackEvent('date_picker_change', {
          tab: 'overview',
          start: date!.dateRange.startDate,
          end: date!.dateRange.endDate,
          totalDays: difference_in_calendar_days(date!.dateRange.startDate, date!.dateRange.endDate)
        });
      }
    );
    autorun(() => {
      if (
        this.reports.datepicker.datepicker &&
        this._rootStore.userStore.publisher?.id &&
        this.reports.attributionWindow.value
      ) {
        this.reports.campaignTypeFilter.reset();
        this.reports.creative.reseteCreativeMetrics();
        this.reports.campaignPagination.reset();
        this.reports.storiesPagination.reset();
      }
    });
    autorun(() => {
      if (this._rootStore.userStore.publisher?.id && this.reports.attributionWindow.value) {
        this.reports.campaignSelection.reset();
        this.reports.storySelection.reset();
        this.reports.activePage.changeTab('campaign');
      }
    });
  }
  isInView = (page: string) => {
    return location.pathname.includes(this.name) && location.pathname.includes(page);
  };
  initialize = (base: string) => {
    const reportsKey = `${base}.${this.name}.reports`;
    if (this.overview) {
      this.overview.datepicker.initialize(
        `${base}.${this.name}.overview`,
        DatePresetsEnum.LAST30DAYS,
        this.isInView('overview') ? this._rootStore.urlParamStore.datepicker : null
      );
    }
    this.reports.datepicker.initialize(
      reportsKey,
      DatePresetsEnum.LAST7DAYS,
      // this is here to avoid setting all useCases to the same dateRange that is the current
      this.isInView('reports') ? this._rootStore.urlParamStore.datepicker : null
    );
    this.reports.activePage.changeTab('campaign');
    this.reports.campaignPagination.initializeRowsPerPage(`${reportsKey}.campaigns`, 5);
    this.reports.storiesPagination.initializeRowsPerPage(`${reportsKey}.stories`, 5);
    this.reports.columnFilter.initialize(reportsKey, this.omittedColumns);
    if (this.withAttributionWindow) {
      this.reports.attributionWindow.initialize(reportsKey);
    }
  };
}
export default UseCase;
