import React from 'react';
import { useStyles } from './baseHeader.styles';
import KeyweeLogo from '@icons/components/Keywee';

interface Props {
  tabs?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  classes?: Partial<{
    root: any;
    headerNavigation: any;
    logoWrapper: any;
    logo: any;
  }>;
}

export const BaseHeader: React.FC<Props> = ({ tabs, children, classes }: Props) => {
  const localClasses = useStyles({ classes });

  return (
    <div className={localClasses.root}>
      <div className={localClasses.headerNavigation}>
        <div className={localClasses.logoWrapper}>
          <KeyweeLogo className={localClasses.logo} />
        </div>
        {tabs}
      </div>
      {children}
    </div>
  );
};
