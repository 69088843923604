import { observable, computed } from 'mobx';
import { DatePresetsEnum } from '@constants/DatePresets';
import qs from 'querystringify';

class UrlParamStore {
  @observable _publisher: string | null = null;
  @observable _dateRange: DatePresetsEnum | { startDate: string; endDate: string } | null = null;

  constructor() {
    const params = qs.parse(location.search) as any;
    if ('publisher' in params) {
      this._publisher = params.publisher;
    }
    if ('period' in params) {
      let temp = '';
      if (params.period.includes('--')) {
        temp = params.period.split('--');
      } else if (params.period) {
        temp = params.period;
      }
      if (temp) {
        this._dateRange =
          typeof temp === 'object' ? { startDate: temp[0], endDate: temp[1] } : (temp as DatePresetsEnum);
      }
    }
  }
  @computed get publisher() {
    if (this._publisher) {
      const currentPublisher = this._publisher;
      return currentPublisher;
    }
    return null;
  }
  @computed get datepicker() {
    if (this._dateRange) {
      const currentDaterange = this._dateRange;
      return currentDaterange;
    }
    return this._dateRange;
  }
}

export default UrlParamStore;
