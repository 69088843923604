import { observable, action } from 'mobx';

class TableSelection {
  @observable value: string[] = [];

  @action selectRows = (selected: string[]) => {
    this.value = selected;
  };
  @action reset = () => {
    this.value = [];
  };
}
export default TableSelection;
