import Datepicker from '@stores/datepicker';
import { DatePresetsEnum } from '@constants/DatePresets';

class Overview {
  datepicker: Datepicker;

  constructor() {
    this.datepicker = new Datepicker(DatePresetsEnum.LAST30DAYS);
  }
}

export default Overview;
