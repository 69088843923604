import { observable, action } from 'mobx';

class FilterColumns {
  private _keyToSaveTo = '';
  private _domainKey = 'column_picker';
  private _initialized = false;
  @observable value: string[] = [];
  @observable hidenValues: string[] = [];
  @action changeFilter = (value: string) => {
    let newValue: string[] = [...this.value];
    if (this.value.includes(value)) {
      newValue = this.value.filter(item => item !== value);
    } else {
      newValue = [...newValue, value];
    }
    this.value = newValue;
    localStorage.setItem(this._keyToSaveTo, JSON.stringify(newValue));
  };
  @action initialize = (fromKey: string, defaultValue?: string[]) => {
    if (this._initialized) {
      return;
    }
    this._initialized = true;
    this._keyToSaveTo = `${fromKey}.${this._domainKey}`;
    const persistedColumnFilter = localStorage.getItem(this._keyToSaveTo);
    if (persistedColumnFilter) {
      this.value = JSON.parse(persistedColumnFilter);
    }
    if (defaultValue) {
      this.hidenValues = defaultValue;
    }
  };
}
export default FilterColumns;
