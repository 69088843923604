import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    width: '100vw',
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }
});
