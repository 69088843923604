import React from 'react';
import { BaseHeader } from '@components/BaseHeader';
import { PageWrapper } from '@components/PageWrapper2';
import CssBaseline from '@material-ui/core/CssBaseline';
import BrowserSupportError from '../../../components/ErrorBoundary/BrowserSupportError';

export class BrowserSupportErrorBoundary extends React.Component<NonNullable<unknown>, { isSupported: boolean }> {
  constructor(props: NonNullable<unknown>) {
    super(props);
    this.state = {
      isSupported: true
    };
  }
  componentDidMount() {
    const browser = this.getBrowser();
    if (!this.isSupported(browser)) {
      this.setState({ isSupported: false });
    }
  }

  getBrowser() {
    const ua = navigator.userAgent;
    let tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
      /* eslint-disable-next-line */
      tem = ua.match(/\Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Edge', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: +M[1]
    };
  }

  isSupported(browser: { name: string; version: number | string }) {
    let supported = true;
    if (browser.name === 'MSIE' || browser.name === 'IE') {
      supported = false;
    }
    return supported;
  }

  render() {
    return !this.state.isSupported ? (
      <React.Fragment>
        <CssBaseline />
        <PageWrapper>
          <BaseHeader />
          <BrowserSupportError />
        </PageWrapper>
      </React.Fragment>
    ) : (
      this.props.children
    );
  }
}
