import { makeStyles, Theme } from '@material-ui/core/styles';

const DRAWER_WIDTH_CLOSED_WIDTH = 78;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
    width: '100%',
    boxSizing: 'border-box',
    padding: `0 40px 0 calc(40px + ${DRAWER_WIDTH_CLOSED_WIDTH})px`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flex: '0 0 80px',
    position: 'sticky',
    top: '0',
    backgroundColor: '#fff',
    marginBottom: '22px',
    zIndex: 4
  },
  headerNavigation: {
    display: 'flex',
    alignItems: 'center'
  },
  logoWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '45px'
  },
  logo: {
    width: '85px',
    height: '25px',
    color: '#314A75'
  },
  toolsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& > button': {
      padding: '8px 25px',
      marginRight: '10px'
    },
    '& > p': {
      padding: '8px 25px'
    }
  },
  date: {
    fontSize: '15px',
    height: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  searchField: {
    marginRight: '25px'
  }
}));
