import { observable, action, computed } from 'mobx';
import DropdownItem from '@utils/DropdownItem';
import { trackEvent } from '@services/MixPanel';

class CampaignTypeFilter {
  @observable filter: DropdownItem = {
    label: 'All Campaigns',
    value: ''
  };
  @action set = (value: DropdownItem) => {
    trackEvent('campaign_type_filter_change', { value: value.value });
    this.filter = value;
  };
  @action reset = () => {
    this.filter = {
      label: 'All Campaigns',
      value: ''
    };
  };
  @computed get value() {
    return this.filter.value ? [this.filter.value] : [];
  }
}

export default CampaignTypeFilter;
